.Logo {
    width: 20vw;
    max-width: 250px;
}
@media only screen and (max-width: 820px) {
    .Logo {
      width: 30vw;
    }
  }
@media only screen and (max-width: 500px) {
    .Logo {
      width: 40vw;
    }
  }

@media only screen and (max-width: 300px) {
    .Logo {
      width: 50vw;
    }
  }

.Text {
    cursor: pointer;
    font-family: 'Cabin', sans-serif;
    font-weight: 100;
}


.LangButton {
    background-color: black;
    color: white;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    padding: 7px;
    cursor: pointer;
}

@media only screen and (max-width: 300px) {
    .LangButton {
      width: 30px;
      height: 30px;
      font-size: 15px;
    }
  }

.Text:after {
    cursor: pointer;    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #0055B8;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    border-radius: 2px;
    width: 0;
  }
  .Text:hover:after {
    cursor: pointer; 
    width: 100%; 
    left: 0; 
  }

  .AdditionalLang{
    position: absolute;
    background-color: black;
    visibility: hidden;
    color: white;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    text-align: center;
    padding: 7px;
    cursor: pointer;
  }

  .AdditionalLangAnimate {
    position: absolute;
    visibility: visible;
    animation-name: move;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    background-color: black;
    color: white;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    text-align: center;
    padding: 7px;
    cursor: pointer;
  }

  .AdditionalLang2 {
    position: absolute;
    background-color: black;
    visibility: hidden;
    color: white;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    text-align: center;
    padding: 7px;
    cursor: pointer;
  }

  .AdditionalLangAnimate2 {
    position: absolute;
    visibility: visible;
    animation-name: move2;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    background-color: black;
    color: white;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    text-align: center;
    padding: 7px;
    cursor: pointer;
  }

  .AdditionalLang3 {
    position: absolute;
    visibility: hidden;
    background-color: black;
    color: white;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    text-align: center;
    padding: 7px;
    cursor: pointer;
  }

  .AdditionalLangAnimate3 {
    position: absolute;
    animation-name: move3;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    background-color: black;
    color: white;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    text-align: center;
    padding: 7px;
    cursor: pointer;
  }


  .Link {
    text-decoration: none;
    color: #0055B8;
    font-family: 'Cabin', sans-serif;
    font-size: 0.9rem;
    display: block;
    white-space: nowrap;
}

  @media only screen and (max-width: 450px) {
    .LangButton{
      padding: 7px;
    }
    .AdditionalLang {
      margin-right: 2px;
      padding: 7px;
    }
    .AdditionalLang2 {
      margin-right: 2px;
      padding: 7px;
    }
    .AdditionalLang3 {
      margin-right: 2px;
      padding: 7px;
    }
  }

  .WrapperContact {
    display: flex;
    flex-direction: column;
  }

  .DropdownContainer{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    justify-content: center;
    background: #ffffffb6;
  }

  .MenuText {
    text-align: center;
    justify-content: center;
    font-family: 'Cabin', sans-serif;
    background: transparent;
    border-top: 1px solid black;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    height: 50px;
    color: black;
    font-size: 1.3rem;
  }

  .MenuText:hover {
    background-color: rgb(235, 235, 235);
  }

  @media only screen and (max-width: 450px) {
    .AdditionalLang{
      animation-name: movePhone;
    }
  }
  @media only screen and (max-width: 450px) {
    .AdditionalLang2{
      animation-name: movePhone2;
    }
  }
  @media only screen and (max-width: 450px) {
    .AdditionalLang3{
      animation-name: movePhone3;
    }
  }



  @keyframes move {
    0%{
      transform: translateY(0px)
    }
    100%{
      transform: translateY(42px)
    }
  }

  @keyframes move2 {
    0%{
      transform: translateY(0px)
    }
    100%{
      transform: translateY(84px)
    }
  }

  @keyframes move3 {
    0%{
      transform: translateY(0px)
    }
    100%{
      transform: translateY(126px)
    }
  }


  @keyframes movePhone {
    0%{
      transform: translateY(0px)
    }
    100%{
      transform: translateY(41px)
    }
  }

  @keyframes movePhone2 {
    0%{
      transform: translateY(0px)
    }
    100%{
      transform: translateY(82px)
    }
  }
  @keyframes movePhone3 {
    0%{
      transform: translateY(0px)
    }
    100%{
      transform: translateY(124px)
    }
  }
