.Container {
    width: 100%;
    height: auto;
    display: flex;
    gap: 7vw;
    flex-wrap: wrap;
    justify-content: space-between;
}


@media only screen and (max-width: 850px) {
    .Container {
        justify-content: center;
    }
}

.Wrapper {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    justify-content: center;
}

.Title {
    padding-left: 5px;
    color: #0055B8;
    font-size: 1.5rem;
    font-family: 'Cabin', sans-serif;
}
