.ImageBox {
    width: 70%;
    height: 70%;
    filter: grayscale(0%);
    background-size: cover;
    display: flex;
    background-position: 40% 70%;
    justify-content: center;
    align-items: flex-end;
    border-radius: 7px;
    max-width: 175px;
    max-height: 175px;
}

.Adnotation {
    font-family: 'Cabin', sans-serif;
    font-size: 0.9rem;
    text-align: center;
    color: black;
}

.Link {
    text-decoration: none;
    color: #0055B8;
    font-size: 1.2rem;
    display: block;
}
.ImageBoxBig {
    width: 80%;
    height: 80%;
    filter: grayscale(0%);
    background-size: cover;
    display: flex;
    background-position: 40% 70%;
    justify-content: center;
    align-items: flex-end;
    border-radius: 7px;
    max-width: 200px;
    max-height: 200px;
}

.Description {
    font-family: 'Cabin', sans-serif;
    font-size: 1.6rem;
    text-decoration: none;
    color: black;
}

.SeeMore {
    font-family: 'Cabin', sans-serif;
    background-color: transparent;
    border: none;
    font-size: 0.9rem;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

}

.DescrptionLong {
    text-align: center;
    font-size: 0.9rem;
}