@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@900&display=swap');

.Photo {
    background-image: url(../../images/maszyna_italia_poziom.jpg);
    background-size: cover;
    background-position: 50% 40%;
    image-rendering: pixelated;
    left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
    z-index: 0;
    min-height: 450px;
}

.ContactUs {
    color: black;
    font-weight: 500;
    font-size: 1.6rem;
    font-family: 'Cabin', sans-serif;
}

.SmallContainer {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 5%;
    padding-right: 5px;

}

@media only screen and (max-width: 450px) {
    .Photo{
        background-image: url(../../images/maszyna_italia_pion.jpg);
    }
}


.Container {
    max-width: 1245px;
    margin: auto;
    width: 97%;
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    
}

.Link {
    text-decoration: none;
    color: #0055B8;
    font-family: 'Cabin', sans-serif;
    font-size: 1.2rem;
    display: block;
    white-space: nowrap;
}

.H1 {
    font-family: 'Cabin', sans-serif;
    font-size: 4rem;
    width: 50%;
    color: white;
    margin-top: 2%;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: auto;
    padding-left: 5px;
}

.Box{
    display: block;
    align-self: flex-end;
    background-color: white;
    margin-left: auto;
    width: auto;
    height: auto;
    margin-bottom: 2%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    border-radius: 7px;
    align-items: center;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
@media only screen and (max-width: 450px) {
    .Box {
        align-self: flex-end;
        margin-top: 2%;
    }
}


.Phone {
    font-family: 'Cabin', sans-serif;
    font-size: 1.4rem;
    width: 100%; 
    text-transform: uppercase;   
    color: white;
    text-align: left;
}

@media only screen and (max-width: 1000px) {
    .H1 {
        font-size: 3.2rem;
        width: 60%;
    }
    .H2 {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 650px) {
    .Photo {
        min-height: auto;
        height: auto;
    }
}


@media only screen and (max-width: 650px) {
    .H1 {
        font-size: 2.8rem;
        width: 80%;
    }
    .H2 {
        font-size: 0.8rem;
        width: 80%;
    }
}

@media only screen and (max-width: 580px) {
    .H1 {
        font-size: 1.7rem;
        width: 100%;
    }
    .H2 {
        font-size: 0.6rem;
        width: 100%;
    }
    .Photo {
        height: auto;
    }
    .Container {
        justify-content: center;
        gap: 30%;
    }
}