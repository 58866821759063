.Container {
    left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
    background: #FFFFFF;
    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
    border-radius: 5px;
    height: auto;
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
}

.SmallContainer {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.Text {
    font-size: 0.9rem;
    font-family: 'Cabin', sans-serif;
    text-align: left;
    margin-left: 3%;
}
.RightText {
    font-size: 0.9rem;
    font-family: 'Cabin', sans-serif;
    text-align: right;
    white-space: nowrap;
    margin-right: 20px;
}

.FlexContainer {
    display: flex;
    max-width: 1245px;
    margin: auto;
    width: 100%;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .Text {
        font-size: 0.6rem;
    }
    .RightText {
        font-size: 0.6rem;
    }
}

@media only screen and (max-width: 300px) {
    .Text {
        font-size: 0.4rem;
    }
    .RightText {
        font-size: 0.4rem;
    }
}
