.Container{
    display: block;
    max-width: 1245px;
    width: 100%;
}

.Header {
    color: #0055B8;
    font-size: 1.5rem;
    font-family: 'Cabin', sans-serif;
    padding-left: 5px;
}



.slick-list {
    max-height: 350px;
}

