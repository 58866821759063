.App {
    display: flex;
    flex-direction: column;
    max-width: 1245px;
    margin: auto;
    width: 100%;
    margin-top: 3vh;
    gap: 20px;
    justify-content: center;
    
}

@media only screen and (max-width: 500px){
    .App {
        gap: 20px;
        margin-top: 1vh;
    }
}

